import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { BrowserRouter } from "react-router-dom";
import { AppConfig } from "./AppConfig";
import { oddsServe } from "./oddsServe";

interface StartEmbedProps {
  token: string;
  config?: AppConfig;
  trackingFunc?: (action: string, leagueId?: string) => void;
  replacerFunc?: (replacer: string) => void;
  reloadFunc?: () => void;
  closeFunc?: () => void;
  pushesFunc?: (uuid: string | null) => void;
}

export function startEmbed({
  token,
  config,
  trackingFunc,
  replacerFunc,
  reloadFunc,
  closeFunc,
  pushesFunc,
}: StartEmbedProps) {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter basename="/tippspiel">
        <App
          ssoId={token}
          config={config}
          trackingFunc={trackingFunc}
          replacerFunc={replacerFunc}
          reloadFunc={reloadFunc}
          closeFunc={closeFunc}
          pushesFunc={pushesFunc}
        />
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

export function startWebEmbed({
  token,
  config,
  trackingFunc,
  replacerFunc,
  reloadFunc,
}: StartEmbedProps) {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter basename="/tippspiel">
        <App
          ssoId={token}
          config={config}
          trackingFunc={trackingFunc}
          replacerFunc={replacerFunc}
          reloadFunc={reloadFunc}
          isWebApp={true}
        />
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

interface StartVereinsheimEmbedProps {
  jwtToken: string;
  config?: AppConfig;
  trackingFunc?: (action: string, leagueId?: string) => void;
  replacerFunc?: (replacer: string) => void;
  closeFunc?: () => void;
  rootId?: string;
  prefix?: string;
}

export function startVereinsheimEmbed({
  jwtToken,
  config,
  trackingFunc,
  replacerFunc,
  closeFunc,
  rootId,
  prefix,
}: StartVereinsheimEmbedProps) {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter basename={`${prefix ?? ""}/tippspiel`}>
        <App
          externalJwtToken={jwtToken}
          config={config}
          trackingFunc={trackingFunc}
          replacerFunc={replacerFunc}
          closeFunc={closeFunc}
          isVereinsheim={true}
        />
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById(rootId ?? "root")
  );
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
(window as any).TippApp = { startEmbed, startVereinsheimEmbed, startWebEmbed };

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
(window as any).oddsServe = oddsServe;
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
(window as any).adition = {};
